/*!
 * AdminKit v3.0.2 (https://adminkit.io/)
 * Copyright 2021 Paul Laros
 * Copyright 2021 AdminKit
 * Licensed under MIT (https://github.com/adminkit/adminkit/blob/master/LICENSE)
 */

// Variables
@import "1-variables/app";

// Bootstrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";

// Theme mixins
@import "2-mixins/button";

// Theme components
@import "3-components/avatar";
@import "3-components/buttons";
@import "3-components/card";
@import "3-components/chart";
@import "3-components/content";
@import "3-components/dropdown";
@import "3-components/feather";
@import "3-components/footer";
@import "3-components/hamburger";
@import "3-components/list-group";
@import "3-components/main";
@import "3-components/navbar";
@import "3-components/reboot";
@import "3-components/sidebar";
@import "3-components/sizing";
@import "3-components/stat";
@import "3-components/tables";
@import "3-components/type";
@import "3-components/wrapper";

// Theme utilities
@import "4-utilities/cursors";

// 3rd party plugins
// @import "~jsvectormap/dist/css/jsvectormap";
// @import "~simplebar/dist/simplebar";
// @import "~flatpickr/dist/flatpickr";

// 3rd party plugin styles
@import "5-vendor/flatpickr";
@import "5-vendor/simplebar";

.pi {
  font-family: 'primeicons' !important;
}
