/* You can add global styles to this file, and also import other style files */
@import "assets/scss/app.scss";
// .p-inputtext {
//     background-color: white !important;
//     color: black !important;
//     width: 100%!important;
// }
// .pi-eye-slash{
//     color: blue !important;
// }
// .pi-eye-slash:before{
//     color: blue !important;
// }
.p-component {
    font-size: small !important;
}
@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";

@import url('https://fonts.googleapis.com/css?family=Poppins:400,700,900');


* { font-family: 'Poppins', sans-serif, feather, 'agGridAlpine' !important; }
